import type { UrlObject } from "node:url";
import AdvertisementBannerV2 from "@/components/AdvertisementBannerV2";
import { Button } from "@/components/Button";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/components/Carousel";
import {
    DialogGallery,
    ImagesGallery,
} from "@/components/CentreDetailsGallery";
import { DesktopFooter } from "@/components/DesktopFooter";
import { DesktopHeader } from "@/components/DesktopHeader";
import {
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogSheet,
    DialogTrigger,
} from "@/components/DialogSheet";
import { FilterRadio } from "@/components/Filter";
import { AmenityIcon } from "@/components/Icons/Amenities";
import { ArrowRightUnfilled } from "@/components/Icons/Arrow";
import { CalendarFilled } from "@/components/Icons/Calendar";
import { CategoriesIcon } from "@/components/Icons/Categories";
import {
    CheveronDownUnfilled,
    CheveronRightUnfilled,
    CheveronUpUnfilled,
} from "@/components/Icons/Chevron";
import { ClockFilled } from "@/components/Icons/Clock";
import { CloseUnfilled } from "@/components/Icons/Close";
import { DocumentTextFilled } from "@/components/Icons/Document";
import { GlobeUnfilled } from "@/components/Icons/Globe";
import { LinkFilled } from "@/components/Icons/Link";
import {
    GoogleMapsFilled,
    MapFilled,
    WazeUnfilled,
} from "@/components/Icons/Maps";
import { SocialIcon } from "@/components/Icons/Social";
import { TemplateFilled } from "@/components/Icons/Template";
import { Loader } from "@/components/Loader";
import { MobileHeader } from "@/components/MobileHeader/v2";
import { ScrollArea } from "@/components/ScrollArea";
import { Separator } from "@/components/Separator";
import {
    ViewLiveAvailabilityButton,
    type ViewLiveAvailabilityButtonProps,
} from "@/components/ViewLiveAvailability";
import { useUser } from "@/lib/auth/useUser";
import { type FragmentType, getFragment, graphql } from "@/lib/gql";
import type {
    Amenities,
    Coordinates,
    PelangganCentreQuery,
} from "@/lib/gql/graphql";
import { Image } from "@/lib/imgproxy";
import { type OrgMetadata, useOrgMetadata } from "@/lib/organisation";
import { getCentreURL } from "@/lib/url";
import { isUniqueBy } from "@/utils";
import { getCentreImage, getDirections } from "@/utils/centre";
import { stringParam } from "@/utils/queryparams";
import { useQuery } from "@apollo/client";
import * as Accordion from "@radix-ui/react-accordion";
import * as Dialog from "@radix-ui/react-dialog";
import { cva, cx } from "class-variance-authority";
import { formatDistanceToNow } from "date-fns";
import type { TFunction } from "i18next";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { type ReactNode, forwardRef, useState } from "react";
import type { LiHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

const amenitiesShowAmount = 6;
const query = graphql(`
    query pelangganCentre($tenantId: ID!, $isLoggedIn: Boolean!) {
        organisation(uid: $tenantId) {
            uid
            name
            services
            metadata
            isCourtsitePartner
            location {
                longitude
                latitude
            }
        }
        onlineServices(tenantId: $tenantId) {
            uid
            name
            serviceMode
            serviceCategory {
                uid
                categoryId
            }
            resources {
                uid
                resourceId
                archived
            }
            metadata
        }
        categories {
            uid
            name
            metadata
        }
        serviceTags(tenantId: $tenantId) {
            serviceId
        }
        myMemberServiceIds(tenantId: $tenantId) @include(if: $isLoggedIn)
    }
`);

const tNs = ["pages/centre", "common"] as const;
type TFunc = TFunction<typeof tNs>;
const Page = (): JSX.Element => {
    const user = useUser();
    const { t, ready } = useTranslation(tNs);
    const router = useRouter();
    const tenantId = stringParam(router.query.orgID);
    const previousRoute = stringParam(router.query.from) || "/explore/category";
    const name = stringParam(router.query.orgName);
    const { data } = useQuery(query, {
        variables: { tenantId, isLoggedIn: !!user },
        fetchPolicy: "cache-and-network",
        skip: !tenantId,
    });
    const org = data?.organisation;
    const categories = data?.categories;
    const orgMeta = useOrgMetadata(org?.metadata);
    const userServices = data?.onlineServices.filter(
        (s) =>
            data.myMemberServiceIds?.includes(s.uid) ||
            !data.serviceTags.some((st) => st.serviceId === s.uid),
    );

    const orgName = org?.name ?? name;

    if (!ready)
        return (
            <div className="flex h-dvh w-full items-center justify-center bg-blue-grey-20 p-8">
                <Loader loading />
            </div>
        );

    return (
        <main className="bg-blue-grey-20 text-blue-grey-900">
            <Head>
                <title>{`${orgName} | Courtsite`}</title>
                <meta
                    name="description"
                    content={`View what ${orgName} has available now to offer at Courtsite today. ${orgMeta?.address}`}
                />
                <link rel="canonical" href={getCentreURL(orgName, tenantId)} />
            </Head>
            <MobileHeader
                left={{ icon: "back", href: previousRoute }}
                className="!grid-cols-[20px_1fr_0px]"
            >
                <div className="flex flex-col gap-1">
                    <h1 className="typography-h3 line-clamp-2 text-wrap font-semibold text-blue-grey-900">
                        {org?.name}
                    </h1>
                    <div className="typography-sub line-clamp-2 text-blue-grey-600">
                        {orgMeta?.cityAndState}
                    </div>
                </div>
            </MobileHeader>
            {org && categories && userServices && (
                <Profile
                    org={org}
                    categories={categories}
                    services={userServices}
                    t={t}
                />
            )}
            <DesktopFooter />
            {/* MobileBottomBar needs to be the last thing to have position sticky work correctly */}
            {org?.isCourtsitePartner && (
                <MobileBottomBar href={getBookNowHref(org)} org={org} t={t} />
            )}
        </main>
    );
};
export default Page;

const getBookNowHref = (o: { uid: string; name: string }): UrlObject => ({
    pathname: "/centre/[orgName]/[orgID]/select",
    query: { orgName: o.name, orgID: o.uid },
});

type BookNowLinkProps = {
    href: UrlObject | string;
    t: TFunc;
};
const MobileBottomBar = (
    props: BookNowLinkProps & ViewLiveAvailabilityButtonProps,
): JSX.Element => (
    <nav className="p-safe-inset-b sticky bottom-0 w-full bg-white p-4 lg:hidden">
        <div className="grid grid-cols-2 gap-3 pb-5">
            <ViewLiveAvailabilityButton org={props.org} />
            <BookNowLink href={props.href} t={props.t} />
        </div>
    </nav>
);

const BookNowLink = (props: BookNowLinkProps): JSX.Element => (
    <Link href={props.href} passHref>
        <Button size="lg" className="w-full" asChild>
            <a className="flex gap-2 capitalize">
                <CalendarFilled className="text-white" />
                {props.t("common:book_now", "Book Now")}
            </a>
        </Button>
    </Link>
);

const cardCss = "flex bg-white p-4 lg:rounded-lg";

const tabVariants = cva(
    "typography-h4 remove-styles-a flex-1 border-0 border-b-[3px] border-solid p-2 pb-[9px] text-center",
    {
        variants: {
            state: {
                default:
                    "border-transparent text-blue-grey-500 hover:text-blue-grey-500",
                selected:
                    "border-primary text-blue-grey-900 hover:text-blue-grey-900",
            },
        },
        defaultVariants: { state: "default" },
    },
);

type QueryOrg = PelangganCentreQuery["organisation"];
type QueryCategories = PelangganCentreQuery["categories"];
type QueryServices = PelangganCentreQuery["onlineServices"];
type ProfileProps = {
    org: QueryOrg;
    categories: QueryCategories;
    services: QueryServices;
    t: TFunc;
};
const Profile = (props: ProfileProps): JSX.Element => {
    const router = useRouter();
    const [moreAmenities, setMoreAmenities] = useState(6);
    const [layoutCategory, setLayoutCategory] = useState("all");

    const { t } = useTranslation(["pages/centre", "amenities", "common"]);
    const org = props.org;
    const orgMeta = useOrgMetadata(org.metadata);
    const orgCats = props.categories
        .filter((c) => org.services.includes(c.uid))
        .map((c) => {
            const metadata = JSON.parse(c.metadata);
            const noOfCourts = props.services
                .filter((s) => s.serviceCategory?.categoryId === c.uid)
                .flatMap((s) => s.resources.filter((r) => !r.archived))
                .filter(isUniqueBy((v) => v.resourceId)).length;
            return {
                resourceNoun: t(
                    "resourceNoun",
                    metadata.resourceNoun || "court",
                    {
                        count: noOfCourts,
                        context: metadata.resourceNoun || "court",
                    },
                ) as string,
                noOfCourts: noOfCourts,
                ...c,
            };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    const { latitude, longitude } = org.location;
    const { wazeLink, gMapsLink } = getDirections(
        latitude,
        longitude,
        org.name,
        orgMeta?.gMapsPlaceId,
    );
    const profileLink = (subpath: "" | "activities" = ""): UrlObject => ({
        pathname: `/centre/[orgName]/[orgID]/${subpath}`,
        query: router.query,
    });

    // This shouldn't happen since org is never undefined at this point
    // Still handled here just in case.
    if (!orgMeta) return <Loader loading />;

    const { phoneNumber, socials: dSocials } = orgMeta;
    const socials = { ...dSocials, phoneNumber };

    const categoriesPricing = orgCats.filter((c) =>
        props.services.some((s) => c.uid === s.serviceCategory?.categoryId),
    );

    return (
        <>
            <article className="top-0 z-10 hidden flex-col items-center bg-blue-grey-20 lg:sticky lg:flex">
                <DesktopHeader />
                <header className="flex w-full max-w-[1056px] rounded-b-lg bg-white p-4">
                    <div className="flex flex-1 flex-col gap-2">
                        <h1 className="typography-h2 font-bold text-blue-grey-900 lg:font-bold">
                            {props.org.name}
                        </h1>
                        <p className="typography-sub m-0 text-blue-grey-600">
                            {orgMeta.cityAndState}
                        </p>
                    </div>
                    {props.org.isCourtsitePartner && (
                        <div className="grid grid-cols-[200px_200px] items-center gap-2">
                            <ViewLiveAvailabilityButton org={org} />
                            <div className="hidden lg:flex">
                                <BookNowLink
                                    href={getBookNowHref(org)}
                                    t={props.t}
                                />
                            </div>
                        </div>
                    )}
                </header>
            </article>
            <article className="mb-4 grid max-w-[1056px] grid-cols-1 gap-2 lg:mx-auto lg:mt-5 lg:gap-4">
                <section>
                    <DialogGallery srcs={orgMeta.images.map((i) => i.url)} />
                </section>
                {orgMeta.announcement && (
                    <Announcement {...orgMeta.announcement} t={t} />
                )}
                <nav className="flex w-full items-center bg-white px-4 lg:rounded-lg">
                    <Link href={profileLink()}>
                        <a className={tabVariants({ state: "selected" })}>
                            {t("home", "Home")}
                        </a>
                    </Link>
                    <div className="h-2">
                        <Separator orientation="vertical" />
                    </div>
                    <Link href={profileLink("activities")}>
                        <a className={tabVariants()}>
                            {t("activities", "Activities")}
                        </a>
                    </Link>
                </nav>
                <div className="flex flex-col gap-4">
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                        <section className="flex flex-col gap-4">
                            <section
                                data-length={orgCats.length}
                                className={cx(
                                    cardCss,
                                    "flex flex-col gap-5 data-[length=0]:hidden",
                                )}
                            >
                                <h3 className="typography-h3 font-semibold text-blue-grey-900">
                                    {t(
                                        "categoriesPricing",
                                        "Categories & Pricing",
                                    )}
                                </h3>
                                <ul className="hidden flex-wrap gap-x-3 gap-y-2 lg:flex">
                                    {categoriesPricing.map((c) => (
                                        <li key={c.uid}>
                                            <CategoryPricingDialog
                                                categories={c}
                                                servicesData={props.services}
                                                orgPrice={orgMeta.price}
                                            >
                                                <CategoryDiv {...c} />
                                            </CategoryPricingDialog>
                                        </li>
                                    ))}
                                </ul>
                                <div className="flex w-full justify-center lg:hidden">
                                    <Carousel
                                        opts={{
                                            dragFree: true,
                                            align: "start",
                                        }}
                                        className="w-[calc(100%-80px)]"
                                    >
                                        <CarouselContent>
                                            {categoriesPricing.map((c) => (
                                                <CarouselItem key={c.uid}>
                                                    <CategoryPricingDialog
                                                        categories={c}
                                                        servicesData={
                                                            props.services
                                                        }
                                                        orgPrice={orgMeta.price}
                                                    >
                                                        <CategoryDiv {...c} />
                                                    </CategoryPricingDialog>
                                                </CarouselItem>
                                            ))}
                                        </CarouselContent>
                                        <CarouselPrevious />
                                        <CarouselNext />
                                    </Carousel>
                                </div>
                            </section>
                            <section
                                data-length={orgMeta.amenities.length}
                                className={cx(
                                    cardCss,
                                    "flex flex-col gap-5 data-[length=0]:hidden",
                                )}
                            >
                                <h3 className="typography-h3 font-semibold text-blue-grey-900">
                                    {t("amenities", "Amenities")}
                                </h3>
                                <ul className="flex flex-wrap gap-x-5 gap-y-2">
                                    {orgMeta.amenities
                                        .slice(0, moreAmenities)
                                        .map((s) => (
                                            <li
                                                key={s}
                                                className="flex items-center gap-2 capitalize"
                                            >
                                                {/* TODO: migrate to org's amenities field instead of metadata */}
                                                <AmenityIcon
                                                    type={
                                                        s.toUpperCase() as Amenities
                                                    }
                                                    props={{
                                                        className:
                                                            "text-blue-grey-400",
                                                    }}
                                                />
                                                {t("amenities:name", s, {
                                                    context: s.toUpperCase(),
                                                })}
                                            </li>
                                        ))}
                                </ul>
                                {orgMeta.amenities.length >
                                    amenitiesShowAmount && (
                                    <p
                                        data-length={orgMeta.amenities.length}
                                        key={moreAmenities ? 1 : 0}
                                        className="typography-sub mt-0.5 cursor-pointer font-semibold underline"
                                        onClick={() =>
                                            setMoreAmenities(
                                                moreAmenities >
                                                    amenitiesShowAmount
                                                    ? amenitiesShowAmount
                                                    : orgMeta.amenities.length,
                                            )
                                        }
                                    >
                                        {moreAmenities > 6
                                            ? t("viewLess", "...less")
                                            : t("viewMore", "View More")}
                                    </p>
                                )}
                            </section>
                        </section>
                        <section className={cx(cardCss, "flex-col gap-5")}>
                            <h2 className="typography-h3 font-semibold capitalize text-blue-grey-900">
                                {t("information", "Venue information")}
                            </h2>
                            <ul className="flex flex-col divide-x-0 divide-y divide-solid divide-blue-grey-50">
                                <LinkItemDialog
                                    icon={
                                        <ClockFilled className="text-primary" />
                                    }
                                    title={t(
                                        "openingHoursPricing",
                                        "Opening hours & Pricing",
                                    )}
                                    content={
                                        <OpeningHoursPricingContent
                                            categories={orgCats}
                                            services={props.services}
                                            orgPrice={orgMeta.price}
                                        />
                                    }
                                />
                                <LinkItemDialog
                                    icon={
                                        <TemplateFilled className="text-primary" />
                                    }
                                    title={t("layout.titlev2", "Venue layout")}
                                    content={
                                        <>
                                            <ImagesGallery
                                                type="layouts"
                                                srcs={orgMeta.centreLayoutImgs
                                                    .filter(
                                                        (l) =>
                                                            l.tags.includes(
                                                                layoutCategory,
                                                            ) ||
                                                            layoutCategory ===
                                                                "all",
                                                    )
                                                    .map((l) => l.url)}
                                            />
                                            <div className="flex flex-wrap gap-2 pt-8">
                                                <FilterRadio
                                                    label={t(
                                                        "common:all",
                                                        "all",
                                                    )}
                                                    value="all"
                                                    selected={layoutCategory}
                                                    onChange={setLayoutCategory}
                                                />
                                                {orgCats.map((c) => (
                                                    <FilterRadio
                                                        key={`filter_${c.uid}`}
                                                        label={c.name}
                                                        value={c.uid}
                                                        selected={
                                                            layoutCategory
                                                        }
                                                        onChange={
                                                            setLayoutCategory
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        </>
                                    }
                                />
                                <LinkItemDialog
                                    icon={
                                        <DocumentTextFilled className="text-primary" />
                                    }
                                    title={t("policy.titlev2", "Venue policy")}
                                    content={
                                        <p className="typography-sub m-0 whitespace-pre-line text-blue-grey-900">
                                            {orgMeta.centrePolicy ||
                                                t(
                                                    "policy.unavailable",
                                                    "Venue has not put up any policies yet.",
                                                )}
                                        </p>
                                    }
                                />
                                <LinkItemDialog
                                    icon={
                                        <LinkFilled className="text-primary" />
                                    }
                                    title={t("socials", "Get connected")}
                                    content={
                                        <SocialsContent socials={socials} />
                                    }
                                />
                            </ul>
                        </section>
                    </div>
                    <section className={cx(cardCss, "flex-col gap-5")}>
                        <h2 className="typography-h3 font-semibold capitalize text-blue-grey-900">
                            {t("gettingThere", "Getting there")}
                        </h2>
                        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-12">
                            <section className="relative mb-auto rounded-xl outline outline-[0.5px] outline-primary/10">
                                <Image
                                    alt="centre_profile_map"
                                    src="/images/centre-profile-map.jpg"
                                    unoptimized
                                    layout="fill"
                                    objectFit="cover"
                                    className="rounded-xl"
                                />
                                <div className="relative flex h-full w-full flex-col gap-2 rounded-xl bg-[linear-gradient(90deg,#FFFFFF_63.22%,rgba(255,255,255,0.2)_100%)] p-4">
                                    <h4 className="typography-h4 font-semibold text-blue-grey-900">
                                        {org.name}
                                    </h4>
                                    <p className="typography-sub m-0 text-blue-grey-400">
                                        {orgMeta.address}
                                    </p>
                                </div>
                            </section>
                            <section>
                                <ul className="flex flex-col divide-x-0 divide-y divide-solid divide-blue-grey-50">
                                    <LinkItemDialog
                                        icon={
                                            <MapFilled className="text-primary" />
                                        }
                                        title={t("directions", "Directions")}
                                        content={
                                            <div>
                                                <ImagesGallery
                                                    type="directions"
                                                    srcs={
                                                        orgMeta.directionImages
                                                    }
                                                />
                                                <p className="typography-sub m-0 whitespace-pre-line text-blue-grey-900">
                                                    {orgMeta.directions}
                                                </p>
                                            </div>
                                        }
                                    />
                                    <LinkItem href={gMapsLink}>
                                        <GoogleMapsFilled className="text-primary" />
                                        {t("gmaps", "Open in Google Maps")}
                                    </LinkItem>
                                    <LinkItem href={wazeLink}>
                                        <WazeUnfilled className="text-primary" />
                                        {t("waze", "Open in Waze")}
                                    </LinkItem>
                                </ul>
                            </section>
                        </div>
                    </section>
                </div>
                <AdvertisementBannerV2
                    page="showVenueProfile"
                    centreNames={org.name}
                    categoryNames={categoriesPricing.map((c) => c.name)}
                    divider
                />
                <VenuesNearby
                    excludedId={org.uid}
                    location={org.location}
                    categories={props.categories}
                    t={t}
                />
            </article>
        </>
    );
};

type SocialsContentProps = {
    socials: OrgMetadata["socials"] & {
        phoneNumber: OrgMetadata["phoneNumber"];
    };
};

const SocialsContent = ({ socials }: SocialsContentProps): JSX.Element => {
    const { t } = useTranslation("pages/centre");
    return (
        <ul className="flex flex-col gap-3">
            {socials.website && (
                <li className="hidden items-center gap-2 has-[a[href]]:flex">
                    <GlobeUnfilled className="text-blue-grey-900" />
                    <a
                        target="_blank"
                        href={socials.website}
                        className="typography-main text-blue-grey-900 underline"
                        rel="noreferrer"
                    >
                        {t("visitWebsite", "Visit website")}
                    </a>
                </li>
            )}
            {socials.facebook && (
                <li className="hidden items-center gap-2 has-[a[href]]:flex">
                    <SocialIcon
                        type="Facebook"
                        props={{ className: "text-blue-grey-900" }}
                    />
                    <a
                        target="_blank"
                        href={socials.facebook}
                        className="typography-main text-blue-grey-900 underline"
                        rel="noreferrer"
                    >
                        Facebook
                    </a>
                </li>
            )}
            {socials.instagram && (
                <li className="hidden items-center gap-2 has-[a[href]]:flex">
                    <SocialIcon
                        type="Instagram"
                        props={{ className: "text-blue-grey-900" }}
                    />
                    <a
                        target="_blank"
                        href={socials.instagram}
                        className="typography-main text-blue-grey-900 underline"
                        rel="noreferrer"
                    >
                        Instagram
                    </a>
                </li>
            )}
            {socials.tiktok && (
                <li className="hidden items-center gap-2 has-[a[href]]:flex">
                    <SocialIcon
                        type="Tiktok"
                        props={{ className: "text-blue-grey-900" }}
                    />
                    <a
                        target="_blank"
                        href={socials.tiktok}
                        className="typography-main text-blue-grey-900 underline"
                        rel="noreferrer"
                    >
                        TikTok
                    </a>
                </li>
            )}
            {socials.twitter && (
                <li className="hidden items-center gap-2 has-[a[href]]:flex">
                    <SocialIcon
                        type="Twitter"
                        props={{ className: "text-blue-grey-900" }}
                    />
                    <a
                        target="_blank"
                        href={socials.twitter}
                        className="typography-main text-blue-grey-900 underline"
                        rel="noreferrer"
                    >
                        Twitter
                    </a>
                </li>
            )}
            {socials.youtube && (
                <li className="hidden items-center gap-2 has-[a[href]]:flex">
                    <SocialIcon
                        type="Youtube"
                        props={{ className: "text-blue-grey-900" }}
                    />
                    <a
                        target="_blank"
                        href={socials.youtube}
                        className="typography-main text-blue-grey-900 underline"
                        rel="noreferrer"
                    >
                        YouTube
                    </a>
                </li>
            )}

            <li className="flex items-center gap-2">
                <SocialIcon
                    type="Whatsapp"
                    props={{ className: "text-blue-grey-900" }}
                />
                <span className="typography-main text-blue-grey-900">
                    {socials.phoneNumber}
                </span>
            </li>
        </ul>
    );
};

const LinkItemDialog = (props: {
    icon: ReactNode;
    title: ReactNode;
    content: ReactNode;
}): JSX.Element => (
    <DialogSheet>
        <DialogTrigger asChild>
            <LinkItem>
                {props.icon}
                {props.title}
            </LinkItem>
        </DialogTrigger>
        <DialogContent>
            <DialogHeader>{props.title}</DialogHeader>
            <DialogDescription className="flex">
                <ScrollArea className="flex flex-1 pr-2">
                    <div className="w-full">{props.content}</div>
                </ScrollArea>
            </DialogDescription>
        </DialogContent>
    </DialogSheet>
);

const OpeningHoursPricingContent = (props: {
    categories: QueryCategories;
    services: QueryServices;
    orgPrice: string;
}): JSX.Element => {
    const services = props.services
        .map((s) => {
            let pricing = JSON.parse(s.metadata).pricing as string;
            if (!pricing) pricing = props.orgPrice;
            return {
                uid: s.uid,
                name: s.name,
                categoryId: s.serviceCategory?.categoryId,
                pricing: pricing,
            };
        })
        .filter(
            (s) =>
                !!s.pricing &&
                props.categories.some((c) => c.uid === s.categoryId),
        );
    const categories = props.categories.filter((c) =>
        services.some((s) => s.categoryId === c.uid),
    );
    return (
        <>
            {categories.length === 1 ? (
                categories.map((c) => (
                    <div key={c.uid} className="">
                        <div className="flex flex-col gap-4">
                            {services
                                .filter((s) => c.uid === s.categoryId)
                                .map((s) => (
                                    <OpeningHourPricingService
                                        key={s.uid}
                                        {...s}
                                    />
                                ))}
                        </div>
                    </div>
                ))
            ) : (
                <Accordion.Root
                    type="single"
                    collapsible
                    className="grid grid-cols-1 gap-4"
                >
                    {categories.map((c) => (
                        <Accordion.Item
                            key={c.uid}
                            value={c.uid}
                            className="rounded-xl border-0.5 border-solid border-blue-grey-100"
                        >
                            <Accordion.Trigger className="group flex w-full cursor-pointer items-center justify-between border-0 bg-transparent p-4 text-blue-grey-900">
                                <Accordion.Header className="typography-h4 font-semibold text-blue-grey-900">
                                    {c.name}
                                </Accordion.Header>
                                <CheveronUpUnfilled className="size-4 text-blue-grey-600 group-data-[state='open']:hidden" />
                                <CheveronDownUnfilled className="size-4 text-blue-grey-600 group-data-[state='closed']:hidden" />
                            </Accordion.Trigger>
                            <Accordion.Content className="flex flex-col gap-4 p-4 pt-0">
                                {services
                                    .filter((s) => c.uid === s.categoryId)
                                    .map((s) => (
                                        <OpeningHourPricingService
                                            key={s.uid}
                                            {...s}
                                        />
                                    ))}
                            </Accordion.Content>
                        </Accordion.Item>
                    ))}
                </Accordion.Root>
            )}
        </>
    );
};

const OpeningHourPricingService = (s: {
    uid: string;
    name: string;
    pricing: string;
}): JSX.Element => (
    <article
        key={s.uid}
        className="divide-x-0 divide-y divide-solid divide-blue-grey-50"
    >
        <h4 className="typography-h4 pb-2 font-bold text-primary">{s.name}</h4>
        <p className="typography-sub m-0 whitespace-pre-line pt-2 text-blue-grey-900">
            {s.pricing}
        </p>
    </article>
);

const CategoryDiv = (c: {
    name: string;
    noOfCourts: number;
    resourceNoun: string;
}): JSX.Element => (
    <div className="my-0.5 flex h-[84px] w-[92px] flex-col items-center gap-3 rounded-lg p-2 text-center text-primary-400 shadow-sm lg:m-0 lg:h-11 lg:w-[122px] lg:flex-row lg:px-2 lg:py-1 lg:text-left lg:shadow-sm">
        <CategoriesIcon type={c.name} />
        <div className="inline-flex flex-col">
            <span className="typography-sub line-clamp-1 flex-1 break-all text-blue-grey-900">
                {c.name}
            </span>
            <span className="typography-tiny line-clamp-1 text-blue-grey-400">
                {c.noOfCourts} {c.resourceNoun}
            </span>
        </div>
    </div>
);

const CategoryPricingDialog = ({
    categories,
    servicesData,
    children,
    orgPrice,
}: {
    categories: { uid: string; name: string };
    servicesData: QueryServices;
    children: ReactNode;
    orgPrice: string;
}): JSX.Element => {
    const services = servicesData
        .map((s) => {
            let pricing = JSON.parse(s.metadata).pricing as string;
            if (!pricing) pricing = orgPrice;
            return {
                uid: s.uid,
                name: s.name,
                categoryId: s.serviceCategory?.categoryId,
                pricing: pricing,
            };
        })
        .filter((s) => !!s.pricing && categories.uid === s.categoryId);
    if (services.length < 1) return <div>{children}</div>;
    return (
        <DialogSheet>
            <DialogTrigger asChild>
                <div className="flex cursor-pointer">{children}</div>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>{categories.name}</DialogHeader>
                <DialogDescription className="flex">
                    <ScrollArea className="flex flex-1 pr-2">
                        <div className="flex w-full flex-col gap-4">
                            {services.map((s) => (
                                <OpeningHourPricingService key={s.uid} {...s} />
                            ))}
                        </div>
                    </ScrollArea>
                </DialogDescription>
            </DialogContent>
        </DialogSheet>
    );
};

const nearbyQuery = graphql(`
    query pelangganCentreNearby($req: CourtsiteSearchRequest!) {
        courtsiteSearch(request: $req) {
            uid
            ...VenueCardOrg
        }
    }
`);
const VenuesNearby = (props: {
    excludedId?: string;
    location: Coordinates;
    categories: QueryCategories;
    t: TFunc;
}): JSX.Element => {
    const { data } = useQuery(nearbyQuery, {
        variables: {
            req: {
                latitude: props.location.latitude,
                longitude: props.location.longitude,
            },
        },
        fetchPolicy: "cache-and-network",
    });
    return (
        <section className={cx(cardCss, "flex-col gap-5")}>
            <h2 className="typography-h3 font-semibold capitalize text-blue-grey-900">
                {props.t("venueNearby", "Venues nearby")}
            </h2>
            <ul className="grid auto-cols-[240px] grid-flow-col grid-rows-[271px] gap-4 overflow-x-auto">
                {data?.courtsiteSearch
                    .filter((o) => o.uid !== props.excludedId)
                    .map((o) => (
                        <CentreCard
                            key={o.uid}
                            org={o}
                            categories={props.categories}
                            t={props.t}
                        />
                    ))}
            </ul>
        </section>
    );
};

const venueCardOrgFragment = graphql(`
    fragment VenueCardOrg on Organisation {
        uid
        name
        services
        metadata
    }
`);
const CentreCard = (props: {
    org: FragmentType<typeof venueCardOrgFragment>;
    categories: QueryCategories;
    t: TFunc;
}): JSX.Element => {
    const org = getFragment(venueCardOrgFragment, props.org);
    const image = getCentreImage(org.metadata);
    const cityState = JSON.parse(org.metadata).cityAndState;
    const href = {
        pathname: "/centre/[orgName]/[orgID]",
        query: { orgName: org.name, orgID: org.uid },
    };
    return (
        <li>
            <Link href={href}>
                <a className="link-plain flex h-full flex-col justify-between rounded-xl border-0.5 border-solid border-blue-grey-50 pb-3">
                    <section className="flex flex-col gap-2">
                        <div className="relative aspect-video w-full">
                            <Image
                                alt={`${org.name} cover image`}
                                src={image || "/images/default_centre.jpg"}
                                className="rounded-t-xl"
                                layout="fill"
                                objectFit="cover"
                                unoptimized={!image}
                                sizes="(max-width: 992px) 250px, 350px"
                            />
                        </div>
                        <div className="flex flex-col gap-0.5 px-2">
                            <p className="typography-micro m-0 line-clamp-1 uppercase text-blue-grey">
                                {props.categories
                                    .filter((c) => org.services.includes(c.uid))
                                    .map((c) => c.name)
                                    .sort((a, b) => a.localeCompare(b))
                                    .join(", ")}
                            </p>
                            <h4 className="typography-h4 line-clamp-2 font-semibold text-blue-grey-900">
                                {org.name}
                            </h4>
                            <p className="typography-tiny m-0 text-blue-grey">
                                {cityState}
                            </p>
                        </div>
                    </section>
                    <p className="typography-h5 m-0 flex items-center gap-2 px-2 font-semibold text-blue-grey-600">
                        {props.t("seeMore", "See more")}
                        <ArrowRightUnfilled className="size-3" />
                    </p>
                </a>
            </Link>
        </li>
    );
};

type LinkItemProps = {
    children?: ReactNode;
    href?: string;
} & LiHTMLAttributes<HTMLLIElement>;
const LinkItem = forwardRef<HTMLLIElement, LinkItemProps>(
    ({ children, href, ...props }, ref): JSX.Element => {
        type CompElem = "div" | "a";
        let Comp: CompElem = "div";
        let compProps = {};
        if (href) {
            Comp = "a";
            compProps = { href, target: "_blank" };
        }
        return (
            <li ref={ref} {...props} className="group">
                <Comp
                    {...compProps}
                    className="link-plain flex w-full cursor-pointer items-center justify-between border-none bg-transparent py-4 group-first:pt-1 group-last:pb-1"
                >
                    <span className="flex items-center gap-2 capitalize">
                        {children}
                    </span>
                    <CheveronRightUnfilled className="size-4 text-blue-grey-600" />
                </Comp>
            </li>
        );
    },
);
LinkItem.displayName = "LinkItem";

// TODO: Pass in locale into formatDistanceToNow
type AnnouncementProps = { text: string; date: Date; t: TFunc };
const Announcement = ({ text, date, t }: AnnouncementProps): JSX.Element => (
    <Dialog.Root>
        <Dialog.Trigger asChild>
            <aside className="flex flex-col items-center gap-3 bg-primary-100 p-4 lg:shadow-md">
                <header className="flex w-full max-w-[1056px] items-center justify-between">
                    <h2 className="typography-h4 font-bold text-blue-grey-700">
                        {t("announcement", "Announcement")}
                    </h2>
                    <p className="typography-sub m-0 capitalize text-blue-grey-400">
                        {formatDistanceToNow(date, { addSuffix: true })}
                    </p>
                </header>
                <div className="typography-sub w-full max-w-[1056px] text-blue-grey-700">
                    <p
                        className={cx(
                            "mb-0.5 line-clamp-2 whitespace-pre-line",
                        )}
                    >
                        {text}
                    </p>
                    <p className="m-0 flex items-center justify-start gap-1 pt-1 font-bold">
                        {t("viewMore", "View More")}
                        <span className="flex size-6 items-center justify-center rounded-full bg-blue-grey-50">
                            <ArrowRightUnfilled className="size-3" />
                        </span>
                    </p>
                </div>
            </aside>
        </Dialog.Trigger>
        <Dialog.Portal>
            <Dialog.Overlay className="fixed inset-0 z-20 bg-black/40" />
            <Dialog.Content className="fixed inset-1/2 z-20 flex h-max max-h-[60%] w-full max-w-[90%] -translate-x-1/2 -translate-y-1/2 flex-col gap-4 overflow-y-auto rounded-xl bg-white p-4 py-8 transition transition-none ease-in-out data-[state=closed]:duration-400 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] lg:max-h-[80%] lg:max-w-[628px]">
                <div className="flex-0 flex justify-between border-0 border-b border-solid border-blue-grey-200 pb-4">
                    <Dialog.Title className="lg:typography-h2 typography-h3 font-bold text-blue-grey-900 lg:font-bold">
                        {t("announcement", "Announcement")}
                    </Dialog.Title>
                    <Dialog.Close
                        aria-label="Close"
                        className="cursor-pointer border-none bg-transparent"
                    >
                        <CloseUnfilled className="size-4 text-blue-grey-900" />
                    </Dialog.Close>
                </div>
                <div className="typography-sub overflow-y-auto whitespace-pre-line text-blue-grey-700">
                    {text}
                </div>
            </Dialog.Content>
        </Dialog.Portal>
    </Dialog.Root>
);
